import React from "react";
import './Navbar.sass';

const Navbar = () => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div id="navbar-content" className="navbar-menu">
        <div className="navbar-end">

          <div className="navbar-item">
            <a className="social-media-icon" href="https://twitter.com/playing_grounds">
              <i className="fab fa-twitter"></i>
            </a>
          </div>

          <div className="navbar-item">
            <a className="social-media-icon" href="https://www.facebook.com/Playing-Grounds-106850020688027">
              <i className="fab fa-facebook-f"></i>
            </a>
          </div>

          <div className="navbar-item">
            <a className="social-media-icon" href="https://www.linkedin.com/company/playing-grounds/">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
