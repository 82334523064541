import React from "react";
import "./App.sass";

import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import FailFalcon from "./components/FailFalcon";
import TeamSection from "./components/TeamSection";
import Footer from "./components/Footer";

import "./fonts/Quantum.otf";

const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <FailFalcon />
      <TeamSection />
      <Footer />
    </div>
  );
}

export default App;
