import React from "react";
import "./Footer.sass";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <div className="columns">
                    <div className="column">
                        <h4 className="title">Contact</h4>
                        <ul>
                            <li>
                                <a href="mailto:wouter@playinggrounds.be">wouter@playinggrounds.be</a>
                            </li>
                            <li>
                                <a href="mailto:sander@playinggrounds.be">sander@playinggrounds.be</a>
                            </li>
                        </ul>
                    </div>

                    <div className="column">
                        <h4 className="title">Follow us:</h4>
                        <ul>
                            <li>
                                <a href="https://twitter.com/playing_grounds">Twitter</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/Playing-Grounds-106850020688027">Facebook</a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/playing-grounds/">LinkedIn</a>
                            </li>
                        </ul>
                    </div>
                </div>

                Picture of Nebula by <a href="https://www.flickr.com/photos/esoastronomy/36032866602/">European Southern Observatory</a>
            </div>
        </footer>
    )
}

export default Footer;
