import React from "react";
import "./Banner.sass";
import { UISubscriptionForm } from "../UISubscriptionForm";

const Banner = () => {
  const pg_logo = "images/logo.svg";

  return (
    <section className="section banner">
      <h1 className="title is-size-1 brand-name is-family-brand has-text-centered">
        <span className="has-text-primary">PLAYING</span> GROUNDS
      </h1>

      <div className="columns">

        <div className="column">
          <img src={pg_logo} className="logo" alt="Playing Grounds logo"/>
        </div>

        <div className="column slogan-container">
          <div className="slogan-content">
            <h2 className="title banner-slogan has-text-primary is-marginless">
                VR experiences
            </h2>
            <h2 className="title banner-slogan is-marginless">
                that are meant to be shared
            </h2>
            <div id="mc_embed_signup">
              <UISubscriptionForm />              
            </div>

          </div>
        </div>

      </div>
    </section>
  );
}

export default Banner;
