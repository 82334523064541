import React from "react";
import "./UISubscriptionForm.sass"

export const UISubscriptionForm = () => (
  <form
    action="https://playinggrounds.us4.list-manage.com/subscribe/post?u=b6f3d3ed25958f238a110a68f&amp;id=270ede3aeb"
    method="post"
    name="mc-embedded-subscribe-form"
    target="_blank">

    <div className="field has-addons">
      <div className="control is-expanded">
        <input type="email" name="EMAIL" className="input email" id="mce-EMAIL" placeholder="Email address" required/>

        {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
        <div className="the-input-field" aria-hidden="true">
          <input type="text" name="b_b6f3d3ed25958f238a110a68f_270ede3aeb"/>
        </div>
      </div>

      <div className="control">
        <input type="submit" value="Join Up!" name="subscribe" id="mc-embedded-subscribe" className="button is-primary"/>
      </div>

    </div>
  </form>
);
