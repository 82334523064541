import React from "react";
import "./TeamSection.sass";

const TeamSection = () => {
  return (
    <div className="section has-background-dark team-section">
      <div className="container">
        <h1 className="title is-2 section-title is-family-brand">Meet the team</h1>

        <div className="columns">
          <div className="column">
            <div className="card has-background-black">
              <div className="card-image">
                <figure className="image">
                  <img src="https://ca.slack-edge.com/TMZASJUS1-UMMTJ3W4T-geae9c39f6fe-512" alt="Wouter Deferme"  />
                </figure>
              </div>
              <div className="card-content">
                <p className="title is-5 is-family-brand has-text-centered">
                  <span className="has-text-primary">Wouter</span> Deferme
                </p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card has-background-black">
              <div className="card-image">
                <figure className="image">
                  <img src="/images/wimsface.png" alt="Wim Verheyen"  />
                </figure>
              </div>
              <div className="card-content">
                <p className="title is-5 is-family-brand has-text-centered">
                  <span className="has-text-primary">Wim</span> Verheyen
                </p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card has-background-black">
              <div className="card-image">
                <figure className="image">
                  <img src="/images/SanderVanhove.jpg" alt="Sander Vanhove"  />
                </figure>
              </div>
              <div className="card-content">
                <p className="title is-5 is-family-brand has-text-centered">
                  <span className="has-text-primary">Sander</span> Vanhove
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamSection;
