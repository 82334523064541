import React from "react";
import  "./FailFalcon.sass";

const FailFalcon = () => {
  const ffLogo = "images/fail_falcon_logo.png";
  const headScreenshot = "images/screenshot7.PNG";
  const screenshots = [
    "images/screenshot1.PNG",
    "images/screenshot2.PNG",
    "images/screenshot5.PNG",
    "images/screenshot3.png",
    "images/screenshot4.PNG",
    "images/screenshot6.PNG",
  ]

  return (
    <section className="section failfalcon-section">
      <div className="logo">
        <img src={ffLogo} alt="Fail Falcon logo" />
      </div>

      <div className="container">
        <div className="card">
          <div className="card-image">
            <figure className="image is-3by2">
              <div style={{ backgroundImage: `url("/images/fade_overlay.png"), url(${headScreenshot})` }}/>
            </figure>
          </div>
          <div className="card-content">
            <div className="content">

              <div className="columns is-mobile">
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[0]})` }}/>
                  </figure>
                </div>
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[1]})` }}/>
                  </figure>
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[2]})` }}/>
                  </figure>
                </div>
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[3]})` }}/>
                  </figure>
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[4]})` }}/>
                  </figure>
                </div>
                <div className="column">
                  <figure className="image is-3by2">
                    <div style={{ backgroundImage: `url(${screenshots[5]})` }}/>
                  </figure>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FailFalcon;
